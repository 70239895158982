const realm = "USAmazon";

const domain = "prod";

export const getDomain = () => {
    return domain;
};

export const getRealm = () => {
    return realm;
};