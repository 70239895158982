import * as KatalMetrics from '@amzn/katal-metrics';
import KatalMetricsDriverSushi from "@amzn/katal-metrics-driver-sushi";
import { getDomain, getRealm } from "src/environment";

const SERVICE_NAME = 'TricorderAnalyticsScript';

const metricsErrorHandler = (err: Error) => {
  console.error(err);
};

const metricsDriver = new KatalMetricsDriverSushi.Builder()
    .withDomainRealm(getDomain(), getRealm())
    .withErrorHandler(metricsErrorHandler)
    .build();

const initialMetricsContext = new KatalMetrics.Context.Builder()
    .withSite('Tricorder')
    .withServiceName(SERVICE_NAME)
    .build();

const initialMetricsPublisher = new KatalMetrics.Publisher(
    metricsDriver,
    metricsErrorHandler,
    initialMetricsContext
);

class MetricsUtility {
    public readonly publisher: KatalMetrics.Publisher;
    constructor() {
        this.publisher = initialMetricsPublisher;
    };

    publishCount = (metric: string, count: number = 1) => {
        this.publisher
            .newChildActionPublisherForMethod('Initialize')
            .publishCounterMonitor(metric, count);
    };
}

export const metrics = new MetricsUtility();